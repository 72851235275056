export * from './authorization.service';
import { AuthorizationService } from './authorization.service';
export * from './business.service';
import { BusinessService } from './business.service';
export * from './checkTransactions.service';
import { CheckTransactionsService } from './checkTransactions.service';
export * from './coupons.service';
import { CouponsService } from './coupons.service';
export * from './customer.service';
import { CustomerService } from './customer.service';
export * from './customerBenefits.service';
import { CustomerBenefitsService } from './customerBenefits.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './display.service';
import { DisplayService } from './display.service';
export * from './equipment.service';
import { EquipmentService } from './equipment.service';
export * from './eventsStatus.service';
import { EventsStatusService } from './eventsStatus.service';
export * from './externalForms.service';
import { ExternalFormsService } from './externalForms.service';
export * from './fuel.service';
import { FuelService } from './fuel.service';
export * from './generalInfo.service';
import { GeneralInfoService } from './generalInfo.service';
export * from './goods.service';
import { GoodsService } from './goods.service';
export * from './jobs.service';
import { JobsService } from './jobs.service';
export * from './loyaltyGoods.service';
import { LoyaltyGoodsService } from './loyaltyGoods.service';
export * from './onlineStore.service';
import { OnlineStoreService } from './onlineStore.service';
export * from './payment.service';
import { PaymentService } from './payment.service';
export * from './poster.service';
import { PosterService } from './poster.service';
export * from './prices.service';
import { PricesService } from './prices.service';
export * from './route.service';
import { RouteService } from './route.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export * from './staff.service';
import { StaffService } from './staff.service';
export * from './tanks.service';
import { TanksService } from './tanks.service';
export * from './temporaryCode.service';
import { TemporaryCodeService } from './temporaryCode.service';
export * from './touristCards.service';
import { TouristCardsService } from './touristCards.service';
export const APIS = [
  AuthorizationService,
  BusinessService,
  CheckTransactionsService,
  CouponsService,
  CustomerService,
  CustomerBenefitsService,
  DefaultService,
  DisplayService,
  EquipmentService,
  EventsStatusService,
  ExternalFormsService,
  FuelService,
  GeneralInfoService,
  GoodsService,
  JobsService,
  LoyaltyGoodsService,
  OnlineStoreService,
  PaymentService,
  PosterService,
  PricesService,
  RouteService,
  SettingsService,
  StaffService,
  TanksService,
  TemporaryCodeService,
  TouristCardsService
];
