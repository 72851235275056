import {
  Injectable,
  signal,
  computed,
  WritableSignal,
  Inject
} from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { AUTH_OPTIONS, AuthOptions } from '../auth.module';
import {
  AuthorizationService,
  BusinessStaff,
  Settings,
  SettingsService,
  StaffService
} from '@incust/api-term';

@Injectable({
  providedIn: 'root'
})
export class AuthHolderService {
  private tokenSignal: WritableSignal<string | null> = signal<string | null>(
    this.getTokenFromLocalStorage()
  );
  private staffTokenSignal: WritableSignal<string | null> = signal<
    string | null
  >(this.getStaffTokenFromLocalStorage());

  private termSettingsSignal: WritableSignal<Settings | null> =
    signal<Settings | null>(this.getSettingsFromLocalStorage());

  isAuthenticated = computed(() => this.tokenSignal() !== null);

  constructor(
    private auth: AuthorizationService,
    private authStaff: StaffService,
    private settingsService: SettingsService,
    @Inject(AUTH_OPTIONS) private options: AuthOptions
  ) {}

  /**
   * Retrieves the token from local storage
   */
  private getSettingsFromLocalStorage(): Settings | null {
    return localStorage.getItem('settings')
      ? JSON.parse(localStorage.getItem('settings') || '{}')
      : null;
  }

  /**
   * Saves the token to local storage and updates the signal
   * @param settings
   */
  private setSettingsToLocalStorage(settings: Settings): void {
    localStorage.setItem('settings', JSON.stringify(settings));
    this.termSettingsSignal.set(settings);
  }

  /**
   * Retrieves the token from local storage
   */
  private getTokenFromLocalStorage(): string | null {
    return localStorage.getItem('auth_token');
  }

  /**
   * Saves the token to local storage and updates the signal
   * @param token The token to store
   */
  private setTokenToLocalStorage(token: string): void {
    localStorage.setItem('auth_token', token);
    this.tokenSignal.set(token);
  }

  /**
   * Saves the token to local storage and updates the signal
   * @param token The token to store
   */
  private setStaffTokenToLocalStorage(token: string): void {
    localStorage.setItem('staff_token', token);
    this.staffTokenSignal.set(token);
  }

  /**
   * Retrieves the token from local storage
   */
  private getStaffTokenFromLocalStorage(): string | null {
    return localStorage.getItem('staff_token');
  }

  /**
   * Removes the token from local storage and updates the signal
   */
  logout(): void {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('staff_token');
    this.tokenSignal.set(null);
    this.staffTokenSignal.set(null);
  }

  /**
   * Retrieves the current token
   * @returns The current token or null
   */
  getToken(): string | null {
    return this.tokenSignal();
  }

  /**
   * Retrieves the current staff token
   * @returns The current staff token or null
   */
  getStaffToken(): string | null {
    return this.staffTokenSignal();
  }

  /**
   * Retrieves the current settings
   * @returns The current settings or null
   */
  getSettings(): Settings | null {
    return this.termSettingsSignal();
  }

  /**
   * Retrieves the current settings
   * @returns The current settings or null
   */
  syncSettings() {
    this.settingsService.incustControllersTermApiSettings().subscribe({
      next: (response) => {
        this.setSettingsToLocalStorage(response);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  /**
   * Retrieves the current options
   * @returns The current options or null
   */
  getOptions(): AuthOptions | null {
    return this.options;
  }

  /**
   * Logs in the user and stores the token upon successful authentication
   * @param credentials User credentials (login and password)
   * @returns Observable for login operation
   */
  login(credentials: { login: string; password: string }) {
    return new Observable((observer: Subscriber<Settings>) => {
      this.auth
        .incustControllersTermApiToken({
          login: credentials.login,
          password: credentials.password
        })
        .subscribe({
          next: (response: Settings) => {
            if (!response?.token) {
              observer.error('No token provided');
              return;
            }
            this.setSettingsToLocalStorage(response);
            this.setTokenToLocalStorage(response.token);
            observer.next(response);
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
          }
        });
    });
  }

  /**
   * Logs in the user and stores the token upon successful authentication
   * @param credentials User credentials (login and password)
   * @returns Observable for login staff operation
   */
  loginStaff(credentials: { code: string }) {
    return new Observable((observer: Subscriber<BusinessStaff>) => {
      this.authStaff
        .incustControllersTermApiStaffSignIn({
          auth_code: credentials.code
        })
        .subscribe({
          next: (response) => {
            if (!response?.token) {
              observer.error('No staff token provided');
              return;
            }
            this.setStaffTokenToLocalStorage(response.token);
            observer.next(response);
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
          }
        });
    });
  }
}
