/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdType } from './idType';

export interface CustomerBenefitsWriteOffOperationData {
  /**
   * Amount credited benefits
   */
  amount: number;
  /**
   * Comment
   */
  comment?: string;
  /**
   * Bonus points currency ISO string
   */
  currency?: string;
  /**
   * User identifier (phone number, card code, QR code). Minimum 3 chars
   */
  id: string;
  id_type?: IdType;
  /**
   * Skip notifications
   */
  skip_message?: boolean | null;
  /**
   * Special account ID
   */
  special_account_id?: string;
  /**
   * Benefit type
   */
  type: CustomerBenefitsWriteOffOperationData.TypeEnum;
}
export namespace CustomerBenefitsWriteOffOperationData {
  export type TypeEnum = 'bonuses' | 'special-account';
  export const TypeEnum = {
    Bonuses: 'bonuses' as TypeEnum,
    SpecialAccount: 'special-account' as TypeEnum
  };
}
