import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthHolderService } from '../services/auth-holder.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authHolderService: AuthHolderService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.authHolderService.getToken();
    const staffToken = this.authHolderService.getStaffToken();
    const settings = this.authHolderService.getSettings();

    if (settings?.staff_sign_in_required) {
      if (!staffToken) {
        this.router.navigate(['/staff']);
      }
      return !!staffToken;
    }

    if (!token) {
      this.router.navigate(['/auth']);
      return false;
    }

    return true;
  }
}
