/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ThresholdsValues } from './thresholdsValues';
import { LoyaltyRuleDependsType } from './loyaltyRuleDependsType';
import { LoyaltyRulePos } from './loyaltyRulePos';
import { RuleImplementationData } from './ruleImplementationData';
import { LoyaltyRuleDependsCalcPeriod } from './loyaltyRuleDependsCalcPeriod';

export interface LoyaltyRule {
  /**
   * Rule action dependency type (by some kinds of amount or by items)
   */
  action_type?: LoyaltyRule.ActionTypeEnum | null;
  /**
   * Rule is active
   */
  active?: number | null;
  /**
   * Rule applicable for API type (for automatic and coupon automatic only)
   */
  applicable_api_scope?: LoyaltyRule.ApplicableApiScopeEnum;
  /**
   * Rule applicable for customer type
   */
  applicable_for_customer?: LoyaltyRule.ApplicableForCustomerEnum | null;
  /**
   * Rule applicable for sale type
   */
  applicable_for_sale?: LoyaltyRule.ApplicableForSaleEnum | null;
  /**
   * Apply rule when bonus points redeemed
   */
  apply_when_bonuses_redeemed?: number | null;
  /**
   * Apply rule when payments from customer accounts
   */
  apply_when_customers_account_payment?: number | null;
  /**
   * Rule applied type (0 - manual, 1 - automatic, 2 - coupon, 3 - display, 4 - corporate manual, 5 - corporate automatic)
   */
  automatic?: number | null;
  /**
   * Card category ID (optional, for rules with retail scope only)
   */
  card_category_id?: string | null;
  /**
   * Refill object type
   */
  charge_type?: LoyaltyRule.ChargeTypeEnum | null;
  /**
   * Combine with other rules flag
   */
  combine?: number | null;
  /**
   * Rule comment
   */
  comment?: string | null;
  /**
   * Corporate customers category ID (optional, for rules with corporate scope only)
   */
  corporate_customer_category_id?: string | null;
  /**
   * Consider redeemed bonuses when calculating the rule value (null if used loyalty settings)
   */
  count_bonuses_redeemed_amount?: number | null;
  /**
   * Batch code of emitted coupons (optional)
   */
  coupon_batch_code?: string | null;
  /**
   * Do not add emitted coupons to the customer\'s wallet (optional)
   */
  coupon_do_not_add_to_wallet?: number;
  depends_calc_period?: LoyaltyRuleDependsCalcPeriod;
  /**
   * Dependencies period limited by current category period
   */
  depends_calc_period_related_to_current_category?: number;
  /**
   * Depencies calc do not applied for item categories (if empty - no exceptions)
   */
  depends_excluded_item_categories?: Array<string> | null;
  /**
   * Depencies calc do not applied for items (if empty - no exceptions)
   */
  depends_excluded_items?: Array<string> | null;
  /**
   * Depencies calc applied for item categories (if empty - applied for any categories)
   */
  depends_included_item_categories?: Array<string> | null;
  /**
   * Depencies calc applied for items (if empty - applied for any items)
   */
  depends_included_items?: Array<string> | null;
  depends_type?: LoyaltyRuleDependsType;
  /**
   * Rule do not applied for item categories (if empty - no exceptions)
   */
  excluded_item_categories?: Array<string> | null;
  /**
   * Rule do not applied for items (if empty - no exceptions)
   */
  excluded_items?: Array<string> | null;
  /**
   * Rule ID
   */
  id?: string | null;
  implementation_data?: RuleImplementationData;
  /**
   * Rule applied for item categories (if empty - applied for any categories)
   */
  included_item_categories?: Array<string> | null;
  /**
   * Rule applied for items (if empty - applied for any items)
   */
  included_items?: Array<string> | null;
  /**
   * Interpret zero quantity of an item as one (for item action types only)
   */
  item_zero_quantity_as_one_quantity?: number;
  /**
   * maximum limit items in check (0 - no limits)
   */
  items_limit_max?: number | null;
  /**
   * minimum limit items in check (0 - no limits)
   */
  items_limit_min?: number | null;
  /**
   * Loyalty ID
   */
  loyalty_id?: string | null;
  /**
   * Apply rule only once per check (for coupon automatic only)
   */
  only_once_per_check?: number;
  /**
   * List of POS where rule is applicable
   */
  pos?: Array<LoyaltyRulePos> | null;
  /**
   * Rule priority(0 - the greatest one)
   */
  priority?: number | null;
  /**
   * Value of promotional bonuses expire date
   */
  promotional_bonuses_expire_date?: string | null;
  /**
   * Promotional bonuses expiration value type
   */
  promotional_bonuses_expire_type?: LoyaltyRule.PromotionalBonusesExpireTypeEnum | null;
  /**
   * Value of promotional bonuses expire
   */
  promotional_bonuses_expire_value?: number | null;
  /**
   * Value of promotional bonuses start date
   */
  promotional_bonuses_starting_date?: string | null;
  /**
   * Promotional bonuses starting value type
   */
  promotional_bonuses_starting_type?: LoyaltyRule.PromotionalBonusesStartingTypeEnum | null;
  /**
   * Value of promotional bonuses start
   */
  promotional_bonuses_starting_value?: number | null;
  /**
   * Rule is scheduled
   */
  scheduled?: number | null;
  /**
   * Rule scope (readonly)
   */
  scope?: LoyaltyRule.ScopeEnum | null;
  /**
   * Special account ID
   */
  special_account_id?: string | null;
  /**
   * Rule valid from time (used if rule is scheduled, in RFC3339)
   */
  time_from?: string | null;
  /**
   * Rule valid to time (used if rule is scheduled, in RFC3339)
   */
  time_to?: string | null;
  /**
   * Rule title
   */
  title?: string | null;
  /**
   * Alternative calc by items dependencies usage
   */
  use_alternative_dependencies_by_items?: number;
  /**
   * Rule valid from date (used if rule is scheduled, in RFC3339)
   */
  valid_after?: string | null;
  /**
   * Rule valid to date (used if rule is scheduled, in RFC3339)
   */
  valid_before?: string | null;
  /**
   * Values set based on thresholds
   */
  value?: Array<ThresholdsValues> | null;
  /**
   * Rule valid at the days (used if rule is scheduled)
   */
  week_days?: Array<string> | null;
}
export namespace LoyaltyRule {
  export type ActionTypeEnum =
    | 'discount-percent-by-item'
    | 'discount-amount-by-item'
    | 'special-price-by-item'
    | 'discount-percent-by-check'
    | 'discount-amount-by-check'
    | 'charge-percent-by-item'
    | 'charge-amount-by-item'
    | 'charge-percent-by-check'
    | 'charge-amount-by-check'
    | 'emission-coupon-by-item'
    | 'emission-coupon-by-check';
  export const ActionTypeEnum = {
    DiscountPercentByItem: 'discount-percent-by-item' as ActionTypeEnum,
    DiscountAmountByItem: 'discount-amount-by-item' as ActionTypeEnum,
    SpecialPriceByItem: 'special-price-by-item' as ActionTypeEnum,
    DiscountPercentByCheck: 'discount-percent-by-check' as ActionTypeEnum,
    DiscountAmountByCheck: 'discount-amount-by-check' as ActionTypeEnum,
    ChargePercentByItem: 'charge-percent-by-item' as ActionTypeEnum,
    ChargeAmountByItem: 'charge-amount-by-item' as ActionTypeEnum,
    ChargePercentByCheck: 'charge-percent-by-check' as ActionTypeEnum,
    ChargeAmountByCheck: 'charge-amount-by-check' as ActionTypeEnum,
    EmissionCouponByItem: 'emission-coupon-by-item' as ActionTypeEnum,
    EmissionCouponByCheck: 'emission-coupon-by-check' as ActionTypeEnum
  };
  export type ApplicableApiScopeEnum = 'all' | 'client' | 'terminal';
  export const ApplicableApiScopeEnum = {
    All: 'all' as ApplicableApiScopeEnum,
    Client: 'client' as ApplicableApiScopeEnum,
    Terminal: 'terminal' as ApplicableApiScopeEnum
  };
  export type ApplicableForCustomerEnum = 'identified' | 'anonymous' | 'all';
  export const ApplicableForCustomerEnum = {
    Identified: 'identified' as ApplicableForCustomerEnum,
    Anonymous: 'anonymous' as ApplicableForCustomerEnum,
    All: 'all' as ApplicableForCustomerEnum
  };
  export type ApplicableForSaleEnum = 'all' | 'first-only' | 'all-but-first';
  export const ApplicableForSaleEnum = {
    All: 'all' as ApplicableForSaleEnum,
    FirstOnly: 'first-only' as ApplicableForSaleEnum,
    AllButFirst: 'all-but-first' as ApplicableForSaleEnum
  };
  export type ChargeTypeEnum =
    | 'regular-bonuses'
    | 'promotional-bonuses'
    | 'special-account';
  export const ChargeTypeEnum = {
    RegularBonuses: 'regular-bonuses' as ChargeTypeEnum,
    PromotionalBonuses: 'promotional-bonuses' as ChargeTypeEnum,
    SpecialAccount: 'special-account' as ChargeTypeEnum
  };
  export type PromotionalBonusesExpireTypeEnum =
    | 'none'
    | 'date'
    | 'day'
    | 'month';
  export const PromotionalBonusesExpireTypeEnum = {
    None: 'none' as PromotionalBonusesExpireTypeEnum,
    Date: 'date' as PromotionalBonusesExpireTypeEnum,
    Day: 'day' as PromotionalBonusesExpireTypeEnum,
    Month: 'month' as PromotionalBonusesExpireTypeEnum
  };
  export type PromotionalBonusesStartingTypeEnum =
    | 'immediately'
    | 'date'
    | 'day'
    | 'month';
  export const PromotionalBonusesStartingTypeEnum = {
    Immediately: 'immediately' as PromotionalBonusesStartingTypeEnum,
    Date: 'date' as PromotionalBonusesStartingTypeEnum,
    Day: 'day' as PromotionalBonusesStartingTypeEnum,
    Month: 'month' as PromotionalBonusesStartingTypeEnum
  };
  export type ScopeEnum = 'retail' | 'corporate';
  export const ScopeEnum = {
    Retail: 'retail' as ScopeEnum,
    Corporate: 'corporate' as ScopeEnum
  };
}
